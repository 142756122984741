import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Form, Row, Col, Input, message, Select, Spin, Divider } from 'antd';
import '../assets/styles/auto-bot.css';
import { PlayCircleOutlined, EyeOutlined, UserOutlined, RobotOutlined, ReloadOutlined } from '@ant-design/icons';
import { GetBotList, BotMakeCall, GetConversationMessages, GetDashboardData, GetCallStatus } from '../api/AutoBot';
import moment from 'moment';

const { Option } = Select;


const StatusButton = ({ record, isProcessing }) => {
    const [buttonText, setButtonText] = useState('Pending');
    const [buttonStyle, setButtonStyle] = useState({
        backgroundColor: '#ffeb3b',
        borderColor: '#ffeb3b',
        color: '#000',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transform: 'translateY(-2px)',
    });

    // Ant Design loading icon
    const antIcon = <ReloadOutlined style={{ fontSize: 16, color: '#000' }} spin />;

    useEffect(() => {
        const checkStatuses = async () => {
            let updatedButtonText = 'Pending';
            let updatedButtonStyle = {
                backgroundColor: '#ffeb3b',
                borderColor: '#ffeb3b',
                color: '#000',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                transform: 'translateY(-2px)',
            };

            if (isProcessing) {
                setButtonText('Pending');
                setButtonStyle({
                    backgroundColor: '#ffeb3b',
                    borderColor: '#ffeb3b',
                    color: '#000',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    transform: 'translateY(-2px)',
                });
                return;
            }

            let status = 'Pending';
            let isStatusInProgress = false;

            // Loop until status changes to Pass or Fail, or a timeout occurs
            for (let i = 0; i < 10; i++) {
                status = await GetCallStatus(record['Internal Conversation ID']);

                if (status === 'Pass') {
                    updatedButtonText = 'Pass';
                    updatedButtonStyle = {
                        backgroundColor: '#bff592',
                        borderColor: '#72f361',
                        color: '#0aa712',
                    };
                    break;
                } else if (status === 'Fail') {
                    updatedButtonText = 'Fail';
                    updatedButtonStyle = {
                        backgroundColor: 'tomato',
                        borderColor: 'red',
                        color: 'white',
                    };
                    break;
                } else if (status === 'in-progress') {
                    updatedButtonText = 'Pending';
                    updatedButtonStyle = {
                        backgroundColor: '#ffeb3b',
                        borderColor: '#ffeb3b',
                        color: '#000',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        transform: 'translateY(-2px)',
                    };
                    isStatusInProgress = true;
                }

                await new Promise(resolve => setTimeout(resolve, 40000)); // Wait 40 seconds before retrying
            }

            // If status remained in-progress after all retries, set to Fail
            if (isStatusInProgress && status !== 'Pass' && status !== 'Fail') {
                updatedButtonText = 'Fail';
                updatedButtonStyle = {
                    backgroundColor: 'tomato',
                    borderColor: 'red',
                    color: 'white',
                };
            }

            setButtonText(updatedButtonText);
            setButtonStyle(updatedButtonStyle);
        };

        checkStatuses();
    }, [record, isProcessing]);

    return (
        <Button style={buttonStyle} className={`status-button ${buttonText === 'Pending' ? 'loading' : ''}`}>
            {buttonText === 'Pending' ? (
                <span>
                    <Spin indicator={antIcon} className="loading-icon" />{buttonText}
                </span>
            ) : (
                buttonText
            )}
        </Button>
    );
};

const AutoBot = () => {
    const [botList, setBotList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedWorkFlow, setSelectedWorkFlow] = useState({});
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [workflowOptions, setWorkflowOptions] = useState([]);
    const [selectedWorkFlowIds, setSelectedWorkFlowIds] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [chatData, setChatData] = useState([]);
    const [isChatModalVisible, setIsChatModalVisible] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [countdownTimer, setCountdownTimer] = useState(null);
    const [fromOptions, setFromOptions] = useState([]); // New state for 'From' dropdown
    const [toOptions, setToOptions] = useState([]); // New state for 'To' dropdown
    const [loading, setLoading] = useState(false); // New state for loading indicator
    const [intervalId, setIntervalId] = useState(null);


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await GetBotList();
                const tempList = response?.botList || [];  // Ensure tempList is an array

                if (Array.isArray(tempList)) {
                    const options = tempList.map(item => (
                        <Option key={item.WorkFlowId} value={item.WorkFlowId}>
                            {item.WorkFlowName}
                        </Option>
                    ));
                    setWorkflowOptions(options);
                } else {
                    console.error('tempList is not an array:', tempList);
                }
            } catch (error) {
                console.error('Error fetching bot list:', error);
            }
        }
        async function fetchNumberList() {
            try {
                const data = await GetBotList(); // Fetch number list from API

                // Ensure the data has a numberList and it's an array
                if (data && Array.isArray(data.numberList)) {
                    const numberList = data.numberList;
                    const fromNumbers = numberList.filter(num => num.type === 'from');
                    const toNumbers = numberList.filter(num => num.type === 'to');

                    setFromOptions(fromNumbers.map(num => (
                        <Option key={num.phone} value={num.phone}>{num.name}</Option>
                    )));

                    setToOptions(toNumbers.map(num => (
                        <Option key={num.phone} value={num.phone}>{num.name}</Option>
                    )));
                } else {
                    console.error('numberList is not an array or is missing.');
                }
            } catch (error) {
                console.error('Error fetching number list:', error);
            }
        }



        fetchData();
        fetchNumberList();
    }, [fromValue, toValue]);


    const handlePlay = async (record) => {
        setLoading(true);
        const selectedWorkFlowNames = selectedWorkFlowIds.map(id => {
            const workflow = botList.find(item => item.WorkFlowId === id);
            return workflow ? workflow.WorkFlowName : '';
        });

        const selectedWorkflowData = {
            WorkFlowId: record,
            WorkFlowName: selectedWorkFlowNames.join(', '),
            From: fromValue,
            To: toValue,
        };

        setSelectedWorkFlow(selectedWorkflowData);
        setIsModalVisible(true);
        // Clear previous columns and data
        setColumns([]);
        setBotList([]);

        try {
            const response = await BotMakeCall(selectedWorkflowData.From, selectedWorkflowData.To, selectedWorkflowData.WorkFlowId);
            if (response.status === 'success') {
                message.success('Call was successful!');
                startCountdown(50);
                const data = response.data;
                setDashboardData(data);
                updateTableColumns(data);
            } else {
                message.error('Call failed!');
            }
        } catch (error) {
            console.error('BotMakeCall error:', error);
            message.error('An error occurred while making the call!');
        } finally {
            setLoading(false);
        }
    };

    const updateTableColumns = (data) => {
        const newColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                hidden: 'true'
            },
            {
                title: 'Call ID',
                dataIndex: 'Call ID',
                key: 'Call ID',
                hidden: 'true'
            },
            {
                title: 'Internal Conversation ID',
                dataIndex: 'Internal Conversation ID',
                key: 'Internal Conversation ID',
            },
            {
                title: 'Workflow Name',
                dataIndex: 'Work Flow',
                key: 'Work Flow',
            },
            {
                title: 'External Conversation ID',
                dataIndex: 'External Conversation ID',
                key: 'External Conversation ID',
                hidden: 'true'
            },
            {
                title: 'Created At',
                dataIndex: 'Created At',
                key: 'Created At',
                render: text => moment(text).format('DD MMM YYYY hh:mm A'),
            },
            {
                title: 'From Phone',
                dataIndex: 'From Phone',
                key: 'From Phone',
                hidden: 'true'
            },
            {
                title: 'Practice Name',
                dataIndex: 'Practice Name',
                key: 'Practice Name',
                hidden: 'true'
            },
            {
                title: 'To Phone',
                dataIndex: 'To Phone',
                key: 'To Phone',
                hidden: 'true'
            },
            {
                title: 'Result',
                dataIndex: 'result',
                key: 'result',
                render: (text, record) => (
                    <StatusButton record={record} />
                ),
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <div>
                        <Button
                            icon={<EyeOutlined />}
                            onClick={() => handleView(record)}
                            style={{ marginRight: 8 }}
                        />

                    </div>
                ),
            }
        ];

        setColumns(newColumns);
        setBotList(data);
    };
    const handleView = async (record) => {
        const internalConversationId = record['Internal Conversation ID'];

        // Start fetching chat data immediately
        await fetchChatData(internalConversationId);

        // Start the live chat loop
        const id = setInterval(async () => {
            await fetchChatData(internalConversationId);
        }, 1000); // Fetch every second

        setIntervalId(id);
        setIsChatModalVisible(true);
    }; const fetchChatData = async (internalConversationId) => {
        try {
            const conversationMessages = await GetConversationMessages(internalConversationId);
            if (Array.isArray(conversationMessages)) {
                setChatData(conversationMessages);
            } else {
                setChatData([conversationMessages]);
            }
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
        }
    };const handleClose = () => {
        // Clear the interval when closing the chat modal
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setIsChatModalVisible(false);
    };const renderChatMessages = () => {
        return chatData.map((message, index) => {
            const isReceived = message.type === 'received';

            return (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: isReceived ? 'flex-start' : 'flex-end',
                        marginBottom: '10px',
                        position: 'relative'
                    }}
                >
                    {isReceived &&
                        <div style={{ marginRight: '8px' }}>
                            <RobotOutlined style={{ fontSize: '24px', borderRadius: '50%', backgroundColor: 'lightblue', padding: '4px', border: '2px solid #ccc' }} />
                        </div>
                    }

                    <div
                        style={{
                            position: 'relative',
                            background: isReceived ? '#f0f0f0' : 'lightblue',
                            padding: '10px',
                            borderRadius: '15px',
                            maxWidth: '60%',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        }}
                    >
                        {message.message}
                        <div style={{
                            fontSize: '12px',
                            color: '#888',
                            position: 'absolute',
                            bottom: '-18px',
                            right: '10px'
                        }}>
                            {message.timestamp}
                        </div>
                    </div>

                    {!isReceived &&
                        <div style={{ marginLeft: '8px' }}>
                            <UserOutlined style={{ fontSize: '24px', borderRadius: '50%', backgroundColor: '#f0f0f0', padding: '4px', border: '2px solid #ccc' }} />
                        </div>
                    }
                </div>
            );
        });
    };
    const handleRefresh = async () => {
        setIsRefreshing(true);

        // Set processing state for all records
        const updatedBotList = botList.map(record => ({
            ...record,
            status: 'Processing',
        }));
        setBotList(updatedBotList);
        startCountdown(50);

        try {
            // Update statuses to 'Pending'
            const statusResults = await Promise.all(botList.map(async (record) => {
                const status = await GetCallStatus(record['Internal Conversation ID']);
                return {
                    ...record,
                    status,
                };
            }));

            setBotList(statusResults);
            // Update the columns to reflect statuses
            updateTableColumns(statusResults);
        } catch (error) {
            console.error('Error refreshing statuses:', error);
        } finally {
            setIsRefreshing(false);
        }
    };
    const handleWorkflowChange = (value) => {
        setSelectedWorkFlowIds(Array.isArray(value) ? value : [value]);
    };

    const startCountdown = (duration) => {
        if (countdownTimer) {
            clearInterval(countdownTimer);
        }

        setCountdown(duration);
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        setCountdownTimer(timer);
    };


    return (
        <div className='auto-bot-list' >
              {loading && (
                <div className="spinner-overlay">
                    <Spin size="large" />
                </div>
            )}
                <center><h1>Auto Bot AI</h1></center>
                <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                    <Row gutter={4} justify="center">
                        <Col span={5}>
                            <Form.Item label="From" required>
                                <Select
                                    placeholder="Select From"
                                    value={fromValue}
                                    onChange={setFromValue}
                                    style={{ width: '100%' }}
                                >
                                    {fromOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="To" required>
                                <Select
                                    placeholder="Select To"
                                    value={toValue}
                                    onChange={setToValue}
                                    style={{ width: '100%' }}
                                >
                                    {toOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={4} justify="center">
                        <Col span={9}>
                            <Form.Item label="WorkFlowName" required>
                                <Select
                                    mode="multiple"
                                    placeholder="Select WorkFlowNames"
                                    onChange={handleWorkflowChange}
                                    value={selectedWorkFlowIds}
                                    maxTagCount={1}
                                    maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
                                    style={{ width: '100%' }}
                                >
                                    {workflowOptions}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Button
                                style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', color: '#ffff' }}
                                onClick={() => handlePlay(selectedWorkFlowIds)}
                                icon={<PlayCircleOutlined />}
                                // disabled={!fromValue || !toValue || selectedWorkFlowIds.length === 0}
                                // disabled={loading} // Disable button while loading
                            >
                            {/* {loading ? <Spin /> : 'Run'} */}
                            Run
                            </Button>
                        </Col>

                        <Col span={1}>
                            <Form.Item>
                                <Button
                                    style={{ backgroundColor: '#f8f9fa', borderColor: '#f8f9fa', color: '#000' }}

                                    onClick={handleRefresh}
                                    icon={<ReloadOutlined />}
                                    loading={isRefreshing}
                                >
                                   
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <Form.Item>
                                <Form.Item>
                                    <Input
                                        style={{ width: '100px', textAlign: 'center' }}
                                        value={countdown > 0 ? `${countdown}s` : 'Ready'}
                                        disabled
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Table
                    columns={columns.map(column => ({
                        ...column,
                        render: (text, record) => (
                            column.key === 'result' ?
                                <StatusButton record={record} isProcessing={isRefreshing} /> :
                                column.render ? column.render(text, record) : text
                        ),
                    }))}
                    dataSource={botList}
                    pagination={{ pageSize: 5 }}
                    style={{ overflowX: 'auto' }}
                />
                <Modal
                    title={<span style={{ fontSize: '20px', color: 'black', padding: '1px', backgroundColor: 'white', borderRadius: '10px', }}>Conversation Messages</span>}
                    visible={isChatModalVisible}
                    onCancel={() => setIsChatModalVisible(false)}
                    footer={null}
                    width={700}
                    style={{ borderRadius: '15px', overflow: 'hidden' }}
                    centered
                ><Divider/>
                    <div
                        style={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                            padding: '10px',
                            background: '#fafafa',
                        }}
                    >
                        {renderChatMessages()}
                    </div>
                </Modal>
        </div>
    );
};

export default AutoBot;
